import GATSBY_COMPILED_MDX from "/opt/build/repo/content/blogs/jamstack-la-arquitectura-estándar-para-la-web-definicion-y-conceptos-basicos/index.mdx";
import React from 'react';
import {GatsbyImage} from 'gatsby-plugin-image';
import {getSpanishDate} from '../../functions/date.function';
import {Calendar} from "react-feather";
import {MainLayout} from '../../layout/main/main.layout';
import {SeoComponent} from '../../components/seo/seo.component';
export var Head = function Head(_ref) {
  var data = _ref.data;
  var siteMetadata = data.site.siteMetadata;
  var _data$mdx$frontmatter = data.mdx.frontmatter, title = _data$mdx$frontmatter.title, author = _data$mdx$frontmatter.author, date = _data$mdx$frontmatter.date, description = _data$mdx$frontmatter.description, thumbnail = _data$mdx$frontmatter.thumbnail;
  var blogMetaData = {
    title: "Blog - Onixdev | " + title,
    siteUrl: siteMetadata.siteUrl,
    url: siteMetadata.siteUrl + "blog" + data.mdx.slug,
    author: author,
    lang: siteMetadata.lang,
    charSet: siteMetadata.charSet,
    image: thumbnail.childImageSharp.gatsbyImageData.images.fallback.src,
    description: "Blog - Quepler Technology | " + description,
    twitterUsername: siteMetadata.twitterUsername,
    menuLinks: siteMetadata.menuLinks
  };
  return React.createElement(React.Fragment, null, React.createElement("meta", {
    name: "resource-type",
    content: "Blog"
  }), React.createElement(SeoComponent, blogMetaData));
};
var PostTemplate = function PostTemplate(children) {
  var data = children.data;
  var _data$site$siteMetada = data.site.siteMetadata, url = _data$site$siteMetada.url, twitterHandle = _data$site$siteMetada.twitterHandle;
  var _data$mdx$frontmatter2 = data.mdx.frontmatter, title = _data$mdx$frontmatter2.title, date = _data$mdx$frontmatter2.date, layout = _data$mdx$frontmatter2.layout, description = _data$mdx$frontmatter2.description, thumbnail = _data$mdx$frontmatter2.thumbnail;
  return React.createElement(MainLayout, {
    siteMetaData: data.site.siteMetadata,
    path: "blog"
  }, React.createElement("div", {
    className: "blog"
  }, React.createElement("div", {
    className: "blog-container"
  }, React.createElement(GatsbyImage, {
    className: "blog-thumbnail",
    image: thumbnail.childImageSharp.gatsbyImageData,
    alt: "Imagen sobre el blog: " + title
  }), React.createElement("div", {
    className: "blog-content"
  }, React.createElement("p", {
    className: "blog-date"
  }, React.createElement(Calendar, null), " ", React.createElement("time", null, getSpanishDate(date))), React.createElement("h2", {
    className: "blog-title"
  }, title), React.createElement("div", {
    className: "blog-description"
  }, React.createElement("h3", {
    className: "blog-description-title"
  }, "Res\xFAmen:"), React.createElement("p", {
    className: "blog-description-content"
  }, description)), React.createElement("div", {
    className: "line"
  }), React.createElement("div", {
    className: "blog-content-mdx-container"
  }, React.createElement("div", {
    className: "blog-content-mdx"
  }, children.children)), React.createElement("div", {
    className: "line"
  })))));
};
PostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
var getPostQuery = "2634532559";
